import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/lib/ink-stains/post-layout/post-layout.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Life is an:`}<br parentName="p"></br>{`
`}{`Endless loop,`}<br parentName="p"></br>{`
`}{`Swing after swing,`}<br parentName="p"></br>{`
`}{`Hitting nothing.`}</p>
    <p>{`Pretending.`}<br parentName="p"></br>{`
`}{`Pretending to strike gold.`}<br parentName="p"></br>{`
`}{`Really, it is their own lives they condone`}<br parentName="p"></br>{`
`}{`Stone after stone after stone.`}</p>
    <p>{`Desperately,`}<br parentName="p"></br>{`
`}{`Trying to hit something,`}<br parentName="p"></br>{`
`}{`anything,`}<br parentName="p"></br>{`
`}{`Lying to yourself.`}<br parentName="p"></br>{`
`}{`Should they be Shown?`}<br parentName="p"></br>{`
`}{`Stone after stone after stone.`}</p>
    <p>{`Imagining, in endless roam,`}<br parentName="p"></br>{`
`}{`Stone after stone after stone.`}</p>
    <p>{`You watch as people party,`}<br parentName="p"></br>{`
`}{`War yet love; Amoung Astarte,`}<br parentName="p"></br>{`
`}{`all but a mindless clone,`}<br parentName="p"></br>{`
`}{`Stone after stone after stone.`}</p>
    <p>{`Digging deeper into a pit, as deep as you dare,`}<br parentName="p"></br>{`
`}{`Imagining; believing, that it will lead somewhere,`}<br parentName="p"></br>{`
`}{`Noone is there, alone,`}<br parentName="p"></br>{`
`}{`Stone after stone after stone.`}</p>
    <p>{`If only souls would turn around.`}<br parentName="p"></br>{`
`}{`Open their eyes.`}<br parentName="p"></br>{`
`}{`Stop pretending.`}<br parentName="p"></br>{`
`}{`Stop digging.`}<br parentName="p"></br>{`
`}{`They would see.`}<br parentName="p"></br>{`
`}{`Stone after stone after stone.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      